import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {FrontendConfiguration} from "@common/configuration";
import {ImplicitFlow, JwtValidator, ThemeProvider} from "@vacasa/react-components-lib";
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import {App} from "./components/app/App";

const params = new URLSearchParams(window.location.search);

let token: string = params.get("token");
const adminConfig = FrontendConfiguration.adminConfig();

Sentry.init({
  dsn: FrontendConfiguration.getSentryDsn(),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
});

// Do not refresh token
const implicitFlowConfig = {...FrontendConfiguration.implicitFlowConfig(), tokenRefresh: false};
const environment = FrontendConfiguration.environment();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const MainApp = () => (
    <ImplicitFlow {...implicitFlowConfig}>
        <App />
    </ImplicitFlow>
);

root.render(
    <React.StrictMode>
      <ThemeProvider theme={DefaultTheme}>
        {environment === FrontendConfiguration.LOCAL ? (
            <MainApp />
        ) : (
            <JwtValidator jwt={token} base64Key={adminConfig.publicKey} storageName={FrontendConfiguration.tokenStorageName}>
                <MainApp />
            </JwtValidator>
        )}
      </ThemeProvider>
    </React.StrictMode>
);
