import {FrontendConfiguration} from "@common/configuration";
import jwt_decode from "jwt-decode";
import React, {useState} from "react";
import "../../styles/base.module.scss";
import {NotificationsContainer} from "../notifications/NotificationsContainer";

export const App: React.FunctionComponent<any> = () => {
  const data: {legacy_reservation_id: number, reservation_email: string} = jwt_decode(window.localStorage.getItem(FrontendConfiguration.tokenStorageName));
  const [resInfo] = useState(data);

  return (
    <NotificationsContainer
      title="Notification logs"
      legacy_reservation_id={resInfo?.legacy_reservation_id.toString()}
      reservation_email={resInfo?.reservation_email}
    />
  );
};
